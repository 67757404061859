.Modal {
    
    border: none;
    background: $black;
    overflow: auto;
    border-radius: 8px;
    outline: none;
    padding: 1em;
    // flex-basis: 100%;

    @include bp('md') {
        padding: 2em;
        border-radius: 24px;
        flex-shrink: 1;
        display: inline-block;
    }

    .close-btn {
        background-color: #00000000;
        border: none;
        background-image: url(../assets/close-x.png);
        background-size: contain;
        background-repeat: no-repeat;
        width: 16px; height: 16px;
        overflow: hidden;
        line-height: 6em;
        position: fixed;
        right: 2em;
        top: 2em;
        @include bp('md') {
            width: 32px; height: 32px;
        }
    }

    span.story_time {
        display: inline-block;
        position: absolute;
        top: 1em;
        left: 1em;
        background-color: $black;
        font-style: italic;
        padding: .5em .5em .5em 2em;
        background-image: url(../assets/icon-time.png);
        background-size: 1em;
        background-repeat: no-repeat;
        background-position: .5em center;
        border-radius: 4px;
        font-size: .68em;
        @include bp('md'){ font-size: 1em; }
    }

    &:has(.story_body[data-has-video=true]) {
        flex-basis: 100%;
    }

    .story_body {
        position: relative;
        iframe {
            aspect-ratio: 16/9;
            max-height: 60vh;
            margin-top: 1em;
            margin-left: -1em;
            margin-right: -1em;
            width: calc(100% + 2em);
            @include bp('md') {
                margin-left: -2em;
                margin-right: -2em;
                width: calc(100% + 4em);
            }
        }
        &:has(iframe){
            span.story_time {
                top: -1em;
                left: 0;
                @include bp('md') {
                    top: -1.5em;
                }
            }
        }
        &:has(.story_image){
            display: flex;
            flex-direction: column;
            gap: 1em;

            .story_image {
                // flex-basis: 75%;
                position: relative;
                // order: 2;
                img { max-width: 100%; max-height: 100%; }
                
            }
            .story_text {
                
                @include bp('lg') {
                    max-width: 400px;
                    width: 40%;
                }
                // flex-basis: 25%;
                // order: 1;
                padding-top: .5em;
                em {
                    font-size: .75em;
                }
                h2 {
                    font-size: 3em;
                    @include bp('md') { font-size: 3.5em; }
                    text-transform: uppercase;
                    line-height: .8;
                    margin-top: .3rem;
                }
                p { 
                    line-height: 1.5;
                    li { 
                        margin-left: 1em;
                        &:first-of-type {
                            margin-top: .5em;
                        }
                        &:last-of-type {
                            margin-bottom: .5em;
                        }
                    }
                }
            }
            .social-cell {
                display: inline-block;
                button:first-of-type {
                    margin-left: 0;
                }
                p { margin-top: 0; }
                button.icon.link.copied:after {
                    top: 0;
                }
            }

            max-height: 71vh;
            overflow-y: auto;
            margin-top: -.5em;
            span.story_time {
                top: 0;
                left: 0;
            }
            .story_image img {
                margin-top: 2em;
            }
            @include bp('lg') {
                max-height: unset;
                overflow-y: unset;
                margin-top: unset;
                gap: 2em;
                flex-direction: row;
                // .story_image { order: 1; }
                span.story_time {
                    top: 1em; left: 1em;
                }
                .story_text { 
                    // order: 2;
                    max-height: 71vh;
                    overflow-y: auto;
                }
                .story_image {
                    // max-height: 71vh;
                    img {
                        margin-top: 0;
                        max-height: 71vh;
                    }
                }
            }
            @include bp('mb'){
            //     .social-cell { display: none; }
                
            }
        }
        margin-bottom: 1em;
        @include bp('md') {
            margin-bottom: 1.5em;
        }
    }
    .story_footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
            width: 305px;
            max-width: 50%;
        }
        .social-cell span {
            padding-right: 0.5em;
            display: inline-block;
        }
        .social-cell * {
            vertical-align: middle;
        }
    }

    
}
.Overlay {
    position: fixed;
    inset: 0px;
    background-color: adjust-color($color: $black-75, $alpha:-.15);
    z-index: 1000;
    padding: 2em;
    display: flex;
    justify-content: center;
    align-items: center;

    @include bp('md') {
        padding: 6.5em;
    }
}

body:has(.ReactModalPortal) {
    overflow-y: clip;
}