@import url('https://fonts.googleapis.com/css2?family=Libre+Caslon+Text:ital@0;1&family=Sofia+Sans+Extra+Condensed:wght@700&family=Sofia+Sans:wght@1..1000&display=swap');

body .App, .Modal {
    font-family: $body-font;

    h1, h2, h3, h4, h5, h6 {
        font-family: $heading-font;
        line-height: 1.1;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}