@use 'sass:color';

html {
    background-color: $black;
    background-image: url(../assets/textures.png);
    color: $beige;
}

.App {

.container {
    margin-left: 1.5rem;
    margin-right: 1.5rem;

    @include bp('lg'){
        margin-left: 2rem;
        margin-right: 2rem;
    }
    @include bp('xl'){
        margin-left: auto;
        margin-right: auto;
        width: calc(100vw - 6rem);
        max-width: 1232px;
    }
    @include bp('xxl'){
        max-width: 1680px;
    }
}


header, footer {
    background-color: adjust-color($black-75, $alpha:-.1, $lightness:-5);
    padding-top: 1em; padding-bottom: 1em;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
header {
    position: sticky;
    top: 0;
    z-index: 999;

    border-bottom: 1px solid $beige-30;

    .logo {
        width: 200px;
        max-width: 40%;
    }
}
footer {
    border-top: 1px solid $beige-30;
    padding-top: 4em;
    padding-bottom: 1.5em;
    @include bp('md'){
        padding-bottom: 4em;
    }

    a.button { font-size: 1.5em; }

    .container { 
        align-items: flex-start;
        h3 { font-size: 2em; margin-top: 0; margin-bottom: 1.4rem; }
        gap: 4em;
        @include bp('mb'){
            flex-wrap: wrap;
        }
    }
    .legal {
        margin-top: 4em;
        align-items: flex-end;

        small {
            font-size: .7em;
            line-height: 2em;
            
            @include bp('md') {
                max-width: 400px;
                &:last-child {
                    text-align: right;
                }
            }

            span {
                display: block;
                margin-top: 2em;
            }
        }

        @include bp('mb'){
            
            margin-top: 2.5em;
            gap: calc(2em * .7);

            small:first-child {
                padding-bottom: 2em;
                border-bottom: 1px solid $beige-30;
            }

            // small { width: 82%; }
            small span { margin-top: 6em; }
        }
    }
}

main {
    .container {
        margin-top: 3.5em;
        margin-bottom: 3.5em;
        &:last-child {
            margin-bottom: 5em;
        }
    }
    .container.intro {
        display: flex;
        gap: 4em;
        align-items: flex-start;

        img {
            width: 397px;
            max-width: 100%;
        }

        p { 
            // columns: 2;
            // column-gap: 1em;
            margin-top: 0;
            margin-bottom: 0.5em;
            line-height: 1.6;
        }

        

        @include bp('mb'){
            flex-wrap: wrap;
            gap: 2em;
            p {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }
    .heading-row {
        display: flex;
        justify-content: space-around;
        li {
            list-style: none;
            text-transform: uppercase;
            font-weight: 400;
            text-align: center;
            font-family: $sub-font;
            flex-basis: calc(100% / 7);
            
            // max-width: 2ch;
            // overflow: hidden;
            @include bp('mb'){
                span { display: none; }
            }

            &:after {
                display: block;
                width: 5px;
                height: 5px;
                background-color: $red;
                content: '';
                margin-top: 7px;
                margin-left: auto;
                margin-right: auto;
                border-radius: 5px;
                transition: width .3s;
            }

            &.selected {
                font-weight: 700;

                &:after {
                    width: 45px;
                    @include bp('mb'){
                        height: 2.4em;
                        margin-top: -1.8em;
                    }
                }

                @include bp('mb'){
                    &:before {
                        content: '';
                        background-image: url(../assets/arrows.svg);
                        width: 64px;
                        height: 24px;
                        position: absolute;
                        display: block;
                        margin-left: -8px;
                        margin-top: -2px;
                    }
                }
            }
        }
        margin-bottom: 1.5em;
        @include bp('lg'){
            margin-left: 3em;
            li {
                // max-width: 3ch;
            }
        }
    }
    .row {
        display: grid;
        grid-template-columns: 3em 1fr;
        --base-row-height: 36px;
        @include bp('md'){
            --base-row-height: 48px;
        }
    
        .hour-col {
            li {
                list-style: none;
                text-transform: uppercase;
                font-weight: 400;
                text-align: left;
                font-family: $sub-font;
                font-size: .625em;
                height: var(--base-row-height);
                line-height: 3px;
                &:last-child {
                    height: 0;
                }
            }
        }
        .grid-col {
            background-image: url(../assets/gridlines.png);
            background-repeat: repeat;
            background-position: left top;
            background-size: var(--base-row-height) auto;
            position: relative;
            
            // overflow-y: clip;
            overflow-x: scroll;
            scroll-snap-type: x mandatory;

            @include bp('lg') {
                overflow-x: visible;
            }

            .week-container {
                display: flex;
                flex-wrap: nowrap;
                width: 700%;
                height: 100%;
                

                .week-col {
                    scroll-snap-align: start;
                    background-color: adjust-color($color: $black-75, $alpha:-.5);
                    flex-basis: 100%; 
                    &:nth-child(even){
                        background-color: adjust-color($color: $black-75, $alpha:-.75);
                    }
                    position: relative;

                    .calendar-entry {
                        position: absolute;
                        width: calc(100% - 1.4em);
                        background-color: $red;
                        padding: .75em 1em;
                        top: calc( 1px + (var(--base-row-height) * var(--start-pos)) );
                        border-radius: 8px;
                        height: calc( (var(--base-row-height) * var(--length)) - 2px );
                        box-sizing: border-box;
                        // background-image: url(../assets/textures.png);
                        // // background-size: ;
                        // background-position: center center;
                        cursor: pointer;
                        transition: all .3s;
                        outline: none;  
                        overflow: hidden;

                        &:before {
                            content:'';
                            background-image: var(--bgpath);
                            background-position: center center;
                            background-size: cover;
                            display: block;
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            z-index: 0;
                            transition: opacity .3s;
                        }
                        &:after {
                            content:'';
                            background-image: url(../assets/textures.png);
                            display: block;
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            z-index: 0;
                            mix-blend-mode: screen;
                        }


                        &.has-video {
                            span:before {
                                content: '';
                                width: 2em;
                                height: 2em;
                                background-image: url(../assets/icon-vid.png);
                                background-size: contain;
                                background-repeat: no-repeat;
                                display: block;
                                margin-bottom: .8rem;
                                margin-top: .4rem;
                            }
                        }

                        span { 
                            font-style: italic;
                            font-size: .75em;
                            font-weight: 600;
                            line-height: 1;
                            display: inline-block;
                            position: relative;
                        }
                        h4 { 
                            text-transform: uppercase;
                            font-size: 2em;
                            line-height: 1;
                            margin-top: .35rem;
                            @include bp('tb'){
                                word-break: break-all;
                            }
                            @include bp('md'){
                                font-size: 1.5em;
                            }
                            position: relative;
                        }

                        &.current {
                            top: calc( (var(--base-row-height) * var(--start-pos)) - 9px );
                            height: calc( (var(--base-row-height) * var(--length)) + 18px );
                            margin-left: -8px;
                            width: calc(100% - (1.4em - 16px));
                            padding-left: calc(1em + 8px);
                            padding-right: calc(1em + 8px);
                            padding-top: calc(.75em + 8px);
                            z-index: 99;
                            filter: drop-shadow(5px 5px 10px $black);
                            background-color: #080606;
                            &:before {
                                opacity: .35;
                            }
                        }
                    }
                    
                }
                @include bp('lg') {
                    width: 100%;
                    .week-col {
                        flex-basis: calc(100% / 7);
                    }
                }
            }
        }
    }
}

}

.button {
    background-color: $red;
    color: $beige;
    font-family: $heading-font;
    padding: .5em 1em;
    line-height: 1;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 4px;
    display: inline-block;
    transition: background-color .3s;

    @include bp('md'){ font-size: 1.5em; }

    &:hover {
        background-color: $red-50;
    }
}
a.icon, button.icon {
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60% auto;
    background-color: $beige;
    transition: background-color .3s;
    border: none;
    line-height: 8em;
    overflow: hidden;
    flex-shrink: 0;
    vertical-align: top;
    cursor: pointer;
    &:not(:first-child){
        margin-left: 0.5em;
    }
    &.fb {
        background-image: url(../assets/icon-fb.png);
    }
    &.link {
        background-image: url(../assets/icon-link.png);
        background-size: 55% auto;
    }
    &.tw {
        background-image: url(../assets/icon-tw.png);
        background-size: 50% auto;
    }
    &:hover {
        background-color: $beige-75;
    }

    @include bp('md'){ 
        width: 40px;
        height: 40px;
    }
}
button.icon.copied:after {
    content: "Link copied";
    display: inline-block;
    position: absolute;
    top: -40px;
    background-image: url(../assets/bubble.svg);
    background-size: contain;
    background-repeat: no-repeat;
    color: $beige;
    padding: .4em .5em 1em;
    right: calc(16px - 44px);
    width: 88px;
    line-height: 1;
    font-family: $heading-font;
    text-transform: uppercase;
    box-sizing: border-box;
    text-align: center;
    font-size: 1.2em;
    @include bp('md'){ 
        right: calc(20px - 44px);
    }
}
.social-cell { position: relative; }


.time-indicator {
    z-index: 100;
    width: 100%;
    position: absolute;
    border-bottom: 1px solid adjust-color(#ffffff, $alpha: -.5);
    &:before, &:after {
        content: '';
        position: absolute;
        left: calc(((100% / 7) * var(--current-day)) - 13.5px);
    }
    &:before {
        background-color: $white;
        width: 7px;
        height: 7px;
        top: -3.5px;
        border-radius: 100%;
    }
    &:after {
        width: calc((100% / 7) + 13.5px);
        border-top: 1px solid $white;
    }
    @include bp('mb'){
        width: 700%;
    }
}