$beige: #e9e6d1;
$beige-75: #CCC9B3;
$beige-30: adjust-color($beige, $alpha:-.7);
$white : #ffffff;
$black: #000000;
$black-75: #252323;
$red: #a71414;
$red-75: #b41317;
$red-50: #7D0000;

//fonts
$heading-font : "Sofia Sans Extra Condensed", sans-serif;
$sub-font: "Sofia Sans", sans-serif;
$body-font : "Libre Caslon Text", serif;

@mixin bp( $point ) { // breakpoints
    @if $point == mb {
        @media ( max-width: 575.9px ) { @content; }
    }
    @else if $point == mbtb {
        @media ( max-width : 767.9px ) { @content; }
    }
    @else if $point == tb {
        @media ( min-width : 576px ) and ( max-width: 767.9px ) { @content; }
    }
    @else if $point == md {
        @media ( min-width : 576px ) { @content; }
    }
    @else if $point == dt { // match bootstrap
        @media ( min-width : 768px ) { @content; }
    }
    @else if $point == lg { // match bootstrap
        @media ( min-width : 992px ) { @content; }
    }
    @else if $point == xl { // match bootstrap
        @media ( min-width : 1200px ) { @content; }
    }
    @else if $point == xxl {
        @media ( min-width : 1920px ) { @content; }
    }
}